import * as React from "react"
import MainLayout from '../layouts/main'
import {useContext} from "react";
import GlobalContext from "../context/GlobalContext";
import {graphql, useStaticQuery} from "gatsby";
import Breadcrumps from "../components/Breadcrumbs";
import Partners from "./home/partners";
import ContributiFiscali from "./home/contributi-fiscali";
import Help from "./home/help";
import HeroPage from "../components/HeroPage";
import Section from "../components/Section";
import SeoComponent from "../layouts/partials/SeoComponent";

const items = [
    {title: 'Le nostre installazioni'}
]

const products = [
    {
        name: 'Tende da sole',
        href: 'tendedasole',
        gallery: 'tenda-illuminazione-01.jpg',
        description: 'Le tende a bracci sono sistemi ombreggianti pensati per schermare dal sole la facciata e la superficie sottostante. Il telo, inserito nel frontale, viene teso orizzontalmente da bracci meccanici dotati di molle in acciaio, generando la spinta necessaria a tendere il tessuto in modo uniforme. Possono essere: cassonate, ovvero ospitano il telo all\'interno di un cassonetto di alluminio; non cassonate hanno il rullo di tessuto che rimane a vista, e può essere protetto da un tettuccio applicato a parete.',
        list: [
            {
                id: '1',
                src: '/prodotti/tendedasole/Installazione/tenda-illuminazione-01.jpg',
                thumb: '/thumb/tendedasole/Installazione/tenda-illuminazione-01.jpg',
            },
            {
                id: '2',
                src: '/prodotti/tendedasole/Installazione/tenda-illuminazione-02.jpg',
                thumb: '/thumb/tendedasole/Installazione/tenda-illuminazione-02.jpg',
            }
        ]
    },
    {
        name: 'Pergole',
        href: 'pergole',
        gallery: 'installazione-pergole.jpg',
        description: 'Le pergole Gibus migliorano efficacemente il comfort termico e visivo, riducendo la spesa energetica e migliorando la qualità degli ambienti. Filtrano e riducono le radiazioni ultraviolette dannose con un\'efficienza che dipende da peso, spessore e colore del tessuto scelto. Le coperture sono realizzate in tessuto PVC che garantisce l\'idro e l\'oleo repellenza, immune a muffe e funghi, resistente a caldo, gelo, intemperie e sbalzi termici. È consigliato integrare la pergola con un tettuccio per la protezione del telo raccolto, soprattutto in caso di neve. Lo scarico delle acque meteoriche awiene attraverso la grondaia integrata nel traverso frontale.',
        list: [
            {
                id: '1',
                src: '/prodotti/pergole/Pergole-90/Installazione/IMG-20191024-WA0020.jpg',
                thumb: '/thumb/pergole/Pergole-90/Installazione/IMG-20191024-WA0020.jpg',
            },
            {
                id: '2',
                src: '/prodotti/pergole/Pergole-90/Installazione/IMG-20191024-WA0021.jpg',
                thumb: '/thumb/pergole/Pergole-90/Installazione/IMG-20191024-WA0021.jpg',
            },
            {
                id: '3',
                src: '/prodotti/pergole/Pergole-90/Installazione/IMG-20191024-WA0022.jpg',
                thumb: '/thumb/pergole/Pergole-90/Installazione/IMG-20191024-WA0022.jpg',
            },
            {
                id: '4',
                src: '/prodotti/pergole/Pergole-90/Installazione/IMG-20191024-WA0023.jpg',
                thumb: '/thumb/pergole/Pergole-90/Installazione/IMG-20191024-WA0023.jpg',
            },
            {
                id: '5',
                src: '/prodotti/pergole/Pergole-90/Installazione/IMG-20191024-WA0024.jpg',
                thumb: '/thumb/pergole/Pergole-90/Installazione/IMG-20191024-WA0024.jpg',
            },
            {
                id: '6',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/ChiusuraLateralePvc/01.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/ChiusuraLateralePvc/01.jpg',
            },
            {
                id: '7',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/ChiusuraLateralePvc/02.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/ChiusuraLateralePvc/02.jpg',
            },
            {
                id: '8',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/ChiusuraLateralePvc/03.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/ChiusuraLateralePvc/03.jpg',
            },
            {
                id: '9',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/Pergola/pergola.jpeg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/Pergola/pergola.jpeg',
            },
            {
                id: '10',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-01.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-01.jpg',
            },
            {
                id: '11',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-02.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-02.jpg',
            },
            {
                id: '12',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-03.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-03.jpg',
            },
            {
                id: '13',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-04.jpeg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-04.jpeg',
            },
            {
                id: '14',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaChiusureScorrevoli/pergola-con-chiusure-vetri-scorrevoli.jpeg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaChiusureScorrevoli/pergola-con-chiusure-vetri-scorrevoli.jpeg',
            },
            {
                id: '15',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaDueModuli/pergola-due-moduli-motorizzata.jpeg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaDueModuli/pergola-due-moduli-motorizzata.jpeg',
            },
            {
                id: '16',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaIlluminazioneLed/pergola-illuminazione-led-01.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaIlluminazioneLed/pergola-illuminazione-led-01.jpg',
            },
            {
                id: '17',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaIlluminazioneLed/pergola-illuminazione-led-02.jpeg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaIlluminazioneLed/pergola-illuminazione-led-02.jpeg',
            },
            {
                id: '18',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaIlluminazioneLed/pergola-illuminazione-led-03.jpeg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaIlluminazioneLed/pergola-illuminazione-led-03.jpeg',
            },
        ]
    },
    {
        name: 'Pergole bioclimatiche',
        href: 'pergolebioclimatiche',
        gallery: 'installazione-bioclimatica.jpg',
        description: 'Tappezzeria Orsilli dal 1949, quindi da ben 3 generazioni, impiega la sapienza artigianale dei propri collaboratori per la cura e la conservazione del vostro mobilio. La ricerca costante della qualità e del design, nonchè l\'utilizzo di tappezzerie di pregio quali pelle, tessuto damascato ed alcantara, consentono di eseguire rivestimenti di divani, poltrone, pouff, sedie e testate del letto. Il tempo e l\'usura non sono amici dei tessuti che rivestono i vostri pezzi di arredo, ma affidandovi ai nostri artigiani potrete restaurarli o ripararli e donare loro nuova vita.',
        list: [
            {
                id: '1',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-01.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-01.jpg',
            },
            {
                id: '2',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-02.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-02.jpg',
            },
            {
                id: '3',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-03.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-03.jpg',
            },
            {
                id: '4',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-04.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-04.jpg',
            },
            {
                id: '5',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-06.jpeg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-06.jpeg',
            },
            {
                id: '6',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-07.jpeg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-07.jpeg',
            },
            {
                id: '7',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-08.jpeg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-08.jpeg',
            },
            {
                id: '8',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-09.jpeg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-09.jpeg',
            },
            {
                id: '9',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-10.jpeg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-10.jpeg',
            },
            {
                id: '10',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/poster.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/poster.jpg',
            },
            {
                id: '11',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-video.mp4',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-video.jpg',
            },
            {
                id: '12',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/pergola-bioclimatica-1.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/pergola-bioclimatica-1.jpg',
            },
            {
                id: '13',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/pergola-bioclimatica-2.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/pergola-bioclimatica-2.jpg',
            },
            {
                id: '14',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/pergola-bioclimatica-3.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/pergola-bioclimatica-3.jpg',
            },
            {
                id: '15',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/pergola-bioclimatica-4.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/pergola-bioclimatica-4.jpg',
            }
        ]
    },
    {
        name: 'Arredamento di interni',
        href: 'arredamentointerni',
        gallery: 'tendaggi-interni-01.jpg',
        description: 'Cosa si intende per complementi d\'arredo? Sono l\'insieme degli oggetti che completano l\'uso di uno spazio interno abitato, pubblico o privato, contribuendo alla definizione della sua architettura. Solitamente questi oggetti sono sottovalutati, invece sono elementi essenziali per un progetto di interior design. I complementi d’arredo sono immancabili e irrinunciabili in un progetto che rispecchi davvero la personalità di chi vive gli ambienti: oltre la loro utilità, creano una continuità stilistica e cromatica all’interno del progetto d’arredamento.',
        list: [
            {
                id: '1',
                src: '/prodotti/tessutitendaggi/tendaggioInterno/13.jpg',
                thumb: '/thumb/tessutitendaggi/tendaggioInterno/13.jpg',
            },
            {
                id: '2',
                src: '/prodotti/tessutitendaggi/tendaggioInterno/14.jpg',
                thumb: '/thumb/tessutitendaggi/tendaggioInterno/14.jpg',
            },
            {
                id: '3',
                src: '/prodotti/tessutitendaggi/tendaggioInterno/15.jpg',
                thumb: '/thumb/tessutitendaggi/tendaggioInterno/15.jpg',
            },
            {
                id: '4',
                src: '/prodotti/tessutitendaggi/tendaggioInterno/16.jpg',
                thumb: '/thumb/tessutitendaggi/tendaggioInterno/16.jpg',
            },
            {
                id: '5',
                src: '/prodotti/tessutitendaggi/tendaggioInterno/12.jpg',
                thumb: '/thumb/tessutitendaggi/tendaggioInterno/12.jpg',
            },
            {
                id: '6',
                src: '/prodotti/tessutitendaggi/tendaggioInterno/11.jpg',
                thumb: '/thumb/tessutitendaggi/tendaggioInterno/11.jpg',
            },
        ]
    },
]

const Installazioni = ({pageContext}) => {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(dettaglio)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]          
                        placeholder: NONE     
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    return (
        <MainLayout pageContext={pageContext}>
            <HeroPage
                image={gContext.getImageFromName(images, 'bg-installazioni.jpeg')}
                imageAlt={'bg-installazioni.jpeg'}
                title={'Le nostre installazioni'}
            />
            <Breadcrumps items={items}/>

            <div className="max-w-[1400px] mx-auto pb-16 px-4 sm:px-6 lg:px-8 relative w-full text-justify">
                <Section items={products} images={images} classes={'!border-y-0 !mt-4'}/>
            </div>

            <ContributiFiscali/>
            <Help/>
            <Partners/>
        </MainLayout>
    )
}

export default Installazioni

export const Head = ({pageContext}) => (
    <SeoComponent pageContext={pageContext} />
)