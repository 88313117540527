import * as React from "react";
import {useContext} from "react";
import GlobalContext from "../context/GlobalContext";
import {Link} from "gatsby";
import GalleryButton from "./GalleryButton";
import {GatsbyImage} from "gatsby-plugin-image";


const Section = ({items, images, classes}) => {
    const gContext = useContext(GlobalContext);
    return (
        <div className={`divide-y divide-black border-y border-black mt-16 ${classes}`}>
            {items.map((item) => (
                <div key={item.name} className="py-10 md:py-16 grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-16">
                    <div className="md:col-span-6 lg:col-span-7 md:col-start-1 flex flex-col items-start space-y-4 sm:space-y-8">
                        <h2 className="title-section-light">{gContext.t(item.name)}</h2>
                        {item.description ? (
                            <p className="text-justify">{gContext.t(item.description)}</p>
                        ) : ('')}
                        {item.href ? (
                            <Link to={gContext.url(item.href)} className="primary-button-product !hidden sm:!inline-flex">
                                <span>{gContext.t('Vai a:')}</span>
                                {gContext.t(item.name)}
                            </Link>
                        ) : ('')}
                    </div>
                    {item.gallery ? (
                        <div className="md:col-span-6 lg:col-span-5 md:col-start-7 lg:col-start-8 flex items-center justify-center">
                            <GalleryButton image={gContext.getImageFromName(images, item.gallery)} imageAlt={`image`} items={item.list}/>
                        </div>
                    ) : (
                        <div className="min-h-[30vh] md:min-h-auto relative md:col-span-6 lg:col-span-5 md:col-start-7 lg:col-start-8 flex items-center justify-center">
                            <div className="absolute inset-0">
                                <GatsbyImage image={gContext.getImageFromName(images, item.image)}
                                             alt={`image`}
                                             loading="eager"
                                             objectFit="cover"
                                             objectPosition="50% 50%"
                                             className="w-full h-full object-cover rounded-xl"/>
                            </div>
                        </div>
                    )}
                    {item.href ? (
                        <Link to={gContext.url(item.href)} className="primary-button-product sm:hidden">
                            <span>{gContext.t('Vai a:')}</span>
                            {gContext.t(item.name)}
                        </Link>
                    ) : ('')}
                </div>
            ))}
        </div>
    )
}
export default Section
